import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/demo/node_modules/@marinda/gatsby-theme-recipes/src/components/MarkdownPagesLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Privacy Policy & Cookies`}</h1>
    <p>
      <i>Tootsie roll dessert muffin ice cream jujubes. Donut liquorice topping lemon drops. Cotton candy cookie gummies chocolate bar pie wafer sesame snaps. Chocolate cake macaroon liquorice jelly beans candy pudding macaroon marzipan brownie.</i></p>
    <h2>{`Jelly-o`}</h2>
    <p>{`Jelly-o `}<b>{`toffee carrot cake cotton candy gummi bears`}</b>{` tiramisu. Marzipan liquorice tootsie roll bear claw halvah liquorice wafer. `}<a parentName="p" {...{
        "href": "#"
      }}>{`Candy canes tiramisu bear`}</a>{` claw cupcake jujubes carrot cake lemon drops icing. Cake brownie apple pie tootsie roll tootsie roll. Pudding icing tootsie roll cotton candy cheesecake lemon drops carrot cake tart. Powder donut tart bear claw sugar plum fruitcake jelly beans. Jelly sweet gummies. Oat cake oat cake carrot cake chupa chups gummi bears danish. Muffin lollipop marshmallow carrot cake pie toffee. Jelly pie halvah cheesecake jujubes. Cotton candy jelly-o muffin. Tiramisu tiramisu cookie cookie sesame snaps halvah cheesecake sweet. Cotton candy dragée marshmallow dragée gummies danish cookie jelly-o.`}</p>
    <h2>{`Gummi bears`}</h2>
    <ul>
      <li parentName="ul">{`Gummi bears sweet roll jelly chupa chups.`}</li>
      <li parentName="ul">{`Ice cream tiramisu icing chocolate lemon drops candy lemon drops fruitcake jelly. Muffin cake dragée bonbon.`}</li>
      <li parentName="ul"><strong parentName="li">{` Halvah gummies sweet pudding `}</strong></li>
      <li parentName="ul">{`Dragée cake donut topping liquorice.`}</li>
    </ul>
    <h3>{`Cheesecake sweet roll`}</h3>
    <p>{`Cheesecake sweet roll  macaroon halvah bear claw. Pie jelly-o gummi bears sesame snaps jujubes chocolate muffin. Carrot cake bear claw biscuit bonbon. Muffin soufflé cookie cotton candy wafer bear claw halvah pastry. Marshmallow danish apple pie oat cake toffee biscuit sesame snaps wafer. Toffee candy marshmallow cake halvah. Chocolate cake cupcake toffee brownie tiramisu bear claw jelly beans fruitcake. Macaroon candy canes caramels jelly caramels jelly beans. Carrot cake dragée cookie liquorice sugar plum caramels. Chocolate caramels donut.`}</p>
    <ol>
      <li parentName="ol">{`Gummi bears sweet roll jelly chupa chups.`}</li>
      <li parentName="ol">{`Ice cream tiramisu icing chocolate lemon drops candy lemon drops fruitcake jelly. Muffin cake dragée bonbon.`}</li>
      <li parentName="ol"><strong parentName="li">{` Halvah gummies sweet pudding `}</strong></li>
      <li parentName="ol">{`Dragée cake donut topping liquorice.`}</li>
    </ol>
    <p>{`Tart toffee tiramisu danish toffee sweet roll cupcake. Wafer pudding cookie jelly sweet roll pie candy marshmallow chocolate bar. Jelly-o marzipan apple pie lollipop. Jelly marshmallow cake jelly candy canes wafer brownie gummi bears. Carrot cake wafer candy canes sweet roll tart sweet muffin muffin. Carrot cake tiramisu fruitcake powder topping soufflé. Macaroon toffee candy marshmallow pastry carrot cake wafer lemon drops. Marzipan apple pie bonbon chocolate cake. Halvah wafer caramels. Gingerbread halvah jelly pie pudding caramels lemon drops. Sweet roll cupcake cheesecake gummies marshmallow. Jujubes topping gingerbread chocolate cake tart bear claw chocolate bear claw. Biscuit danish icing.`}</p>
    <p>{`Tart topping muffin cookie cotton candy. Muffin bear claw dragée bonbon pastry sesame snaps chocolate pudding tootsie roll. Gummies cheesecake jelly-o macaroon liquorice sesame snaps cake carrot cake. Donut dessert chupa chups lollipop chocolate cake powder jujubes. Muffin liquorice dragée. Toffee bear claw danish chocolate. Marzipan marshmallow sesame snaps macaroon. Sugar plum toffee liquorice lollipop bear claw. Icing powder cookie gummi bears chocolate wafer croissant candy canes chocolate bar. Halvah donut dragée pastry gingerbread lemon drops jelly-o. Lollipop lollipop candy canes sesame snaps chocolate bar ice cream chupa chups cake. Gummies toffee cake donut cookie.`}</p>
    <p>{`Marzipan chocolate bar brownie toffee pastry. Sesame snaps lemon drops fruitcake pudding jelly-o. Marzipan macaroon cookie jelly. Lollipop apple pie gingerbread topping fruitcake cotton candy. Lollipop cotton candy cheesecake cake gummies. Cheesecake marzipan sugar plum bonbon carrot cake cotton candy dragée. Cake wafer cupcake sugar plum cheesecake. Jujubes chocolate cake gummi bears. Wafer powder candy canes chocolate cake ice cream marzipan pie. Cotton candy gummies oat cake tootsie roll danish halvah. Gingerbread pastry jujubes. Croissant tiramisu powder lemon drops. Muffin carrot cake ice cream dessert gingerbread. Sesame snaps topping caramels sugar plum icing cotton candy.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      